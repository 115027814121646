<template>
  <div class="page-profile">
    <div class="card card-custom">

      <div v-if="isVerified" class="card-body">
        <!--begin::profile-header-->
        <div class="profile-header">
          <span class="title">
            {{i18n.profileEdit}}
          </span>
        </div>
        <!--end::profile-header-->
        <!--begin::profile-body-->
        <div class="profile-body">
          <b-form class="form-profile">
            <!--begin::form-group(id)-->
            <b-form-group>
              <div class="form-label">
                <label>{{i18n.id}}</label>
              </div>
              <b-form-input
                  v-model="myId"
                  class="form-control form-control-solid py-5 px-6 active"
                  disabled
              ></b-form-input>
            </b-form-group>
            <!--begin::form-group(password)-->
            <b-form-group>
              <div class="form-label">
                <label>{{i18n.pwd}}</label>
              </div>
              <b-form-input
                  class="form-control form-control-solid py-5 px-6"
                  type="password"
                  id="profile-input-password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-2-password-feedback"
                  placeholder="Password"
              ></b-form-input>

              <b-form-invalid-feedback id="input-2-password-feedback">
                {{i18n.pwdInvalid}}
              </b-form-invalid-feedback>
            </b-form-group>
            <!--begin::form-group(id)-->
            <b-form-group>
              <div class="form-label">
                <label>{{i18n.name}}</label>
              </div>
              <b-form-input
                  class="form-control form-control-solid py-5 px-6"
                  id="profile-input-name"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-4-name-feedback"
                  placeholder="UserName"
              ></b-form-input>

              <b-form-invalid-feedback id="input-4-name-feedback">
               {{i18n.nameInvalid}}
              </b-form-invalid-feedback>
            </b-form-group>
            <!--begin::form-group(password)-->
            <b-form-group>
              <div class="form-label">
                <label>{{i18n.email}}</label>
              </div>
              <b-form-input
                  class="form-control form-control-solid py-5 px-6"
                  id="profile-input-email"
                  type="email"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-email-feedback"
                  placeholder="Email Address"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-email-feedback">
                {{i18n.emailInvalid}}
              </b-form-invalid-feedback>
            </b-form-group>
            <!--begin::form-group(id)-->
            <b-form-group>
              <div class="form-label">
                <label>{{i18n.phoneNumber}}</label>
              </div>
              <b-form-input
                  id="profile-input-phone"
                  class="form-control form-control-solid py-5 px-6"
                  v-model="$v.form.phoneNo.$model"
                  :state="validateState('phoneNo')"
                  aria-describedby="input-3-phone-feedback"
                  placeholder="Phone Num (Only Number)"
              ></b-form-input>

              <b-form-invalid-feedback id="input-3-phone-feedback">
                {{i18n.phoneNumberInvalid}}
              </b-form-invalid-feedback>
            </b-form-group>
            <!--begin::button-group-->
            <div class="button-group row">
              <div class="col">
                <b-button variant="dark" class="w-100" @click="updateProfile">
                  <span class="font-weight-bold font-size-h5">{{i18n.save}}</span>
                </b-button>
              </div>
              <div class="col">
                <b-button variant="" class="w-100" @click="goBack">
                  <span class="font-weight-bold font-size-h5">{{i18n.cancel}}</span>
                </b-button>
              </div>
            </div>
            <!--end::button-group-->
          </b-form>
          <button class="text-muted pt-3 float-right" @click="deleteMember()"><span><ins>{{i18n.resignMembership}} ></ins></span></button>
        </div>
        <!--begin::profile-body-->
      </div>

      <!--begin::Body-->
      <div v-else class="card-body">
        <!--begin::profile-top-->
        <div class="profile-top">
          <span class="text font-weight-bold">
            {{i18n.pwdCheck}}
          </span>
        </div>
        <!--end::profile-top-->
        <!--begin::profile-body-->
        <div class="profile-body">
          <b-form class="form-profile">
            <!--begin::form-group(id)-->
            <b-form-group
                label=""
                label-for="input-id"
            >
              <div class="form-label">
                <label>{{i18n.id}}</label>
              </div>
              <b-form-input
                  v-model="myId"
                  class="form-control form-control-solid py-5 px-6 active"
                  id="input-id"
                  name="input-id"
                  disabled
              ></b-form-input>
            </b-form-group>
            <!--begin::form-group(password)-->
            <b-form-group
                label=""
                label-for="input-password"
                class="mb-0"
            >
              <div class="form-label">
                <label>{{i18n.pwd}}</label>
              </div>
              <b-form-input
                  v-model="pwd"
                  class="form-control form-control-solid py-5 px-6"
                  type="password"
                  placeholder="Password"
                  @keyup.enter="verifyMember"
              ></b-form-input>
            </b-form-group>
            <!--begin::button-group-->
            <div class="button-group row">
              <div class="col">
                <b-button variant="primary" class="w-100" v-bind:class="{'spinner spinner-light spinner-right': loaded}" @click="verifyMember">
                  <span class="font-weight-bold font-size-h5">{{i18n.confirm}}</span>
                </b-button>
              </div>
            </div>
            <!--end::button-group-->
          </b-form>
        </div>
        <!--begin::profile-body-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {mapGetters} from 'vuex';
import {GET_MY_PROFILE, SET_PROFILE, UPDATE_MY_PROFILE, VERIFY, ACT_DELETE_MEMBER} from '@/core/services/store/auth.module';
import {getItem, getResult, isSuccess, lengthCheck} from '@/core/services/funcs';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import {email, minLength, numeric, required} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  mixins: [validationMixin],
  name: "Profile",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.i18n.profile, icon: 'flaticon2-user'}]);
  },
  beforeMount() {
    this.getProfile((item) => {
      this.mno = item.mno
      this.form.name = item.memberNm;
      this.form.email = item.memberEmail;
      this.form.phoneNo = (item.phoneNo || '').replace(/-/g, '');
    });
  },
  computed: {
    ...mapGetters(['myId', 'myNm', 'myEmail', 'myPhoneNo']),
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      phoneNo: {
        required,
        numeric,
        minLength: minLength(8)
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  data() {
    return {
      i18n:{
        profileEdit: i18n.t('profile.profileEdit'),
        id: i18n.t('profile.id'),
        pwd: i18n.t('profile.pwd'),
        pwdInvalid: i18n.t('profile.pwdInvalid'),
        name: i18n.t('profile.name'),
        nameInvalid: i18n.t('profile.nameInvalid'),
        email: i18n.t('profile.email'),
        emailInvalid: i18n.t('profile.emailInvalid'),
        phoneNumber: i18n.t('profile.phoneNumber'),
        phoneNumberInvalid: i18n.t('profile.phoneNumberInvalid'),
        save: i18n.t('profile.save'),
        cancel: i18n.t('profile.cancel'),
        pwdCheck: i18n.t('profile.pwdCheck'),
        confirm: i18n.t('profile.confirm'),
        profile: i18n.t('profile.profile'),
        profileConfirm: i18n.t('profile.profileConfirm'),
        profileEditted: i18n.t('profile.profileEditted'),
        error: i18n.t('profile.error'),
        resignMembership: i18n.t('profile.resignMembership'),
        resignSuccess: i18n.t('profile.resignSuccess'),
        resignConfirm: i18n.t('profile.resignConfirm'),
      },

      isVerified: false,
      // verify
      pwd: '',
      // profile
      name: '',
      email: '',
      phoneNo: '',
      password: '',

      form: {
        name: '',
        email: '',
        phoneNo: '',
        password: ''
      },
      mno : 0,

      loaded: false,
    }
  },
  methods: {
     deleteMember(){
      const yesCallback = () => {
        this.$store.dispatch(ACT_DELETE_MEMBER, this.mno).then(resp =>{
          this.$router.push('/signin')
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.resignSuccess, color: 'success'});
        })
      }

      this.$store.dispatch(`${ACT_SHOW_CONFIRM_ALERT}`, {
        info: {title: this.i18n.resignConfirm},
        callback: yesCallback,
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    goBack(){
      this.$router.go(-1);
    },
    verifyMember(){
      if(this.loaded) return;

      if(!this.pwd){
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.pwdInvalid});
        return;
      }

      this.loaded = true;
      this.$store.dispatch(VERIFY, {password: this.pwd})
      .then(resp => {
        if(isSuccess(resp)){
          this.isVerified = true;
        }else{
          this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
        }
        this.loaded = false;
      })
      .catch(e => {
        console.error(e)
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
      })
    },
    getProfile(callback){
      this.$store.dispatch(GET_MY_PROFILE)
      .then(resp => {
        if(lengthCheck(resp)){
          callback(getItem(resp));
        }
      })
      .catch(e => {
        console.error(e);
      });
    },
    mutProfile(item){
      this.$store.commit(SET_PROFILE, item);
    },
    updateProfile(){
      if(this.loaded) return;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.loaded = true

      const yesCallback = () => {
        this.$store.dispatch(UPDATE_MY_PROFILE, {
          name: this.$v.form.name.$model,
          password: this.$v.form.password.$model,
          email: this.$v.form.email.$model,
          phoneNo: this.$v.form.phoneNo.$model
        })
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.profileEditted, color: 'success'});
            // 성공시 프로필 수정
            this.getProfile(this.mutProfile);
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
          }
          this.loaded = false
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.profileConfirm},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });
    },
  }
};
</script>
